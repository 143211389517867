/*
 * File : deviceService.ts
 * Created : March 2023
 * Authors :
 * Synopsis:
 *
 * Copyright 2023 Audinate Pty Ltd and/or its licensors
 *
 */
import { useContext, useEffect } from 'react';
import { filterAudioInput, filterAudioOutput } from '../helpers/audio';
import {
  useAllMediaDevicesStore,
  useAudioOutputDeviceSupportedStore,
  useAudioUsageAllowedStore,
  useInputMediaDevicesStore,
  useOutputMediaDevicesStore,
} from '../store/monitoring';
import { AuthContext } from '../../context/authContext';

export const useFetchDevices = () => {
  const context = useContext(AuthContext);
  const allowed = useAudioUsageAllowedStore((state) => state.allowed);
  const setMediaDevices = useAllMediaDevicesStore(
    (state) => state.setMediaDevices
  );
  const setOutputMediaDevices = useOutputMediaDevicesStore(
    (state) => state.setOutputMediaDevices
  );
  const setInputMediaDevices = useInputMediaDevicesStore(
    (state) => state.setInputMediaDevices
  );

  useEffect(() => {
    const getDevices = async () => {
      let devices = await navigator.mediaDevices?.enumerateDevices();
      if (!devices || devices.length === 0) {
        setOutputMediaDevices([]);
        setInputMediaDevices([]);
        setMediaDevices(devices);
        return;
      }

      const audioOutput = filterAudioOutput(devices);
      const audioInput = filterAudioInput(devices);
      setMediaDevices(devices);
      setOutputMediaDevices(audioOutput);
      setInputMediaDevices(audioInput);
    };
    setInterval(async () => {
      if (!allowed) {
        return;
      }
      await getDevices().catch((err) => {
        context.setError(err.message);
      });
    }, 1000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allowed]);
};

export const useAllowMediaDevices = (isMonitoringVersion: boolean) => {
  const context = useContext(AuthContext);
  const allowed = useAudioUsageAllowedStore((state) => state.allowed);
  const allow = useAudioUsageAllowedStore((state) => state.allow);
  const disallow = useAudioUsageAllowedStore((state) => state.disallow);
  const mediaDevices = useAllMediaDevicesStore((state) => state.mediaDevices);
  const audioOutputDeviceSupported = useAudioOutputDeviceSupportedStore(
    (state) => state.supported
  );

  useEffect(() => {
    const allowMediaDevices = async () => {
      if (!audioOutputDeviceSupported && isMonitoringVersion) {
        allow();
        return;
      }

      if (!allowed && mediaDevices.length === 0) {
        await navigator.mediaDevices
          .getUserMedia({
            audio: audioOutputDeviceSupported
              ? {
                  autoGainControl: false,
                  channelCount: 2,
                  echoCancellation: false,
                  latency: 0,
                  noiseSuppression: false,
                  sampleRate: 48000,
                  sampleSize: 16,
                }
              : true,
          })
          .then(() => {
            allow();
          })
          .catch(() => {
            if (isMonitoringVersion) {
              allow();
              context.setWarning(
                `${
                  audioOutputDeviceSupported
                    ? 'Please allow audio usage to be able to select output device.'
                    : ''
                }`
              );
            } else {
              disallow();
              context.setWarning(
                `Please allow audio usage. ${
                  audioOutputDeviceSupported
                    ? ''
                    : 'Browser does not support selecting output device - using default system selected.'
                }`
              );
            }
          });
      }
    };
    setInterval(async () => {
      await allowMediaDevices().catch((err) => {
        console.error(err);
        context.setError(err.message);
      });
    }, 1000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [audioOutputDeviceSupported, mediaDevices.length, allowed]);
};
