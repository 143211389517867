/*
 * File : audio.ts
 * Created : March 2023
 * Authors :
 * Synopsis:
 *
 * Copyright 2023 Audinate Pty Ltd and/or its licensors
 *
 */
import {
  AudioOptionType,
  ReceiveChannelConfigurationWithLink,
  TransmitChannelConfigurationWithLink,
} from '../types/audio';
import { DeviceKind } from '../enums/audioEnums';

export const createNewAudioOptionType = (
  audioOptionType: AudioOptionType
): AudioOptionType => {
  return { ...audioOptionType };
};

export const filterAudioOutput = (
  mediaDevices: MediaDeviceInfo[] | null
): MediaDeviceInfo[] => {
  if (mediaDevices === null) {
    return [];
  }
  return mediaDevices.filter((device) => device.kind === DeviceKind.AUDIO_OUT);
};

export const filterAudioInput = (
  mediaDevices: MediaDeviceInfo[] | null
): MediaDeviceInfo[] => {
  if (mediaDevices === null) {
    return [];
  }
  return mediaDevices.filter((device) => device.kind === DeviceKind.AUDIO_IN);
};

export const channelLinkIdOrDanteName = (
  channel:
    | ReceiveChannelConfigurationWithLink
    | TransmitChannelConfigurationWithLink
) => {
  return channel.linkId ?? channel.danteName;
};
