/*
 * File : App.tsx
 * Created : March 2023
 * Authors :
 * Synopsis:
 *
 * Copyright 2023 Audinate Pty Ltd and/or its licensors
 *
 */
import { lazy, Suspense, useContext, useEffect } from 'react';
import { IntlProvider } from 'react-intl';
import { Navigate, Route, Routes } from 'react-router-dom';
import messages from './lang/compiled/en.json';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import ToastMessageWrapper from './components/ToastMessageWrapper';
import ContextualHelpWrapper from './components/ContextualHelpWrapper';
import { AuthContext } from './context/authContext';
import ModalMessageWrapper from './components/ModalMessageWrapper';
import Loading from './components/Loading';
import { AppTypeEnum, isAppType } from './ts/enums/appTypeEnum';
import { useLoginStore } from './ts/store/auth';
import UnauthenticatedRoutes from './routes/UnauthenticatedRoutes';

const MonitoringAppRoutes = lazy(() => import('./routes/MonitoringAppRoutes'));
const ContributionAppRoutes = lazy(
  () => import('./routes/ContributionAppRoutes')
);

function App() {
  const context = useContext(AuthContext);
  const isLoggedIn = useLoginStore((state) => state.isLoggedIn);
  const setIsLoggedIn = useLoginStore((state) => state.setIsLoggedIn);
  useEffect(() => {
    function calculateVh() {
      // Calculate 1vh value in pixels
      // based on window inner height
      var vh = window.innerHeight;
      // Set the CSS variable to the root element
      // Which is equal to 1vh
      document.documentElement.style.setProperty('--hundred-vh', vh + 'px');
    }

    // Initial calculation
    calculateVh();

    // Re-calculate on resize
    window.addEventListener('resize', calculateVh);

    // Re-calculate on device orientation change
    window.addEventListener('orientationchange', calculateVh);
    return () => {
      window.removeEventListener('resize', calculateVh);
      window.removeEventListener('orientationchange', calculateVh);
    };
  }, []);

  useEffect(() => {
    const handleStorage = (_a: StorageEvent) => {
      setIsLoggedIn();
    };

    window.addEventListener('storage', handleStorage);
    return () => window.removeEventListener('storage', handleStorage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <IntlProvider messages={messages} locale="en">
      <Routes>
        {isLoggedIn ? (
          <>
            <Route
              path="*"
              element={
                <ToastMessageWrapper
                  onCloseSuccess={() => context.emptySuccess()}
                  onCloseError={() => context.emptyError()}
                  onCloseWarning={() => context.emptyWarning()}
                  errorMessage={context.error}
                  successMessage={context.success}
                  warningMessage={context.warning}
                >
                  <ContextualHelpWrapper>
                    <ModalMessageWrapper>
                      <DndProvider backend={HTML5Backend}>
                        <Suspense fallback={<Loading fixedPosition />}>
                          {(isAppType(AppTypeEnum.MONITORING) && (
                            <MonitoringAppRoutes />
                          )) ||
                            (isAppType(AppTypeEnum.CONTRIBUTION) && (
                              <ContributionAppRoutes />
                            ))}
                        </Suspense>
                      </DndProvider>
                    </ModalMessageWrapper>
                  </ContextualHelpWrapper>
                </ToastMessageWrapper>
              }
            />
            <Route path="*" element={<Navigate to="/configure" />} />
            <Route path="/" element={<Navigate to="/configure" />} />
          </>
        ) : (
          <Route path="*" element={<UnauthenticatedRoutes />} />
        )}
      </Routes>
    </IntlProvider>
  );
}

export default App;
