/*
 * File : AudioMonitoring.ts
 * Created : March 2023
 * Authors :
 * Synopsis:
 *
 * Copyright 2023 Audinate Pty Ltd and/or its licensors
 *
 */
let ctxGlobal: AudioContext;

export default class AudioMonitoring {
  private readonly audioElement: HTMLAudioElement;
  private readonly browserSupportsOutputDeviceSelection;

  constructor() {
    if (!ctxGlobal) ctxGlobal = new AudioContext({ latencyHint: 0 });
    this.audioElement = new Audio();
    this.browserSupportsOutputDeviceSelection =
      AudioMonitoring.doesBrowserSupportOutputDevice();
  }

  /**
   *  https://developer.mozilla.org/en-US/docs/Web/API/HTMLMediaElement/setSinkId
   */
  async setOutputDevice(device: string | MediaDeviceInfo) {
    if (!this.browserSupportsOutputDeviceSelection) {
      return false;
    }

    // @ts-ignore
    await this.audioElement['setSinkId'](
      typeof device === 'string' ? device : device.deviceId
    );
  }

  addStream(stream: MediaStream) {
    this.audioElement.srcObject = stream;
  }

  /**
   * Listen to an audio stream
   * Pass `null` to stop
   */
  async listen(stream: MediaStream | null) {
    this.audioElement.srcObject = stream;

    if (stream) {
      await this.audioElement.play();
    } else {
      this.audioElement.pause();
    }

    await ctxGlobal.resume();
  }

  static doesBrowserSupportOutputDevice() {
    return 'setSinkId' in new Audio();
  }
}
