/*
 * File : MonitoringReceivers.tsx
 * Created : April 2023
 * Authors :
 * Synopsis:
 *
 * Copyright 2023 Audinate Pty Ltd and/or its licensors
 *
 */
import React, { FormEvent, useContext, useEffect, useState } from 'react';
import Table from '../../components/Table';
import styles from './Monitoring.module.scss';
import { ReactComponent as Arrow } from '../../assets/icons/arrow.svg';
import ToggleSwitch from 'src/components/ToggleSwitch';
import { AuthContext } from '../../context/authContext';
import { MonitoringConfiguration } from '../../graphql/graphqlGenerated';
import Combobox from '../../components/Combobox/Combobox';
import {
  AudioOptionType,
  ReceiveChannelConfigurationWithLink,
} from '../../ts/types/audio';
import {
  channelLinkIdOrDanteName,
  createNewAudioOptionType,
} from '../../ts/helpers/audio';
import {
  useAudioMonitoringStore,
  useAudioOutputDeviceSupportedStore,
  useAudioUsageAllowedStore,
  useOutputMediaDevicesStore,
  useRtcPeerConnectionStateStore,
  useStreamsStore,
} from '../../ts/store/monitoring';
import { useRxMakeAudioOffer } from '../../ts/services/offerService';
import {
  useStopAllRxAudio,
  useStopRxAudio,
} from '../../ts/services/audioService';
import linkIcon from '../../assets/icons/linkIcon.svg';
import { v4 as uuidV4 } from 'uuid';
import classNames from 'classnames';

interface MonitoringReceiversProps {
  deviceName: string;

  configurationId: string;

  rtcConfiguration: RTCConfiguration | null;

  monitoringConfiguration: MonitoringConfiguration | null;
}

const MonitoringReceivers = ({
  deviceName,
  configurationId,
  monitoringConfiguration,
  rtcConfiguration,
}: MonitoringReceiversProps) => {
  // STATES
  const context = useContext(AuthContext);
  const [rxChannels, setRxChannels] = useState<
    ReceiveChannelConfigurationWithLink[]
  >([]);
  const [useRxDanteChannelNames, setUseRxDanteChannelNames] =
    useState<boolean>(false);
  const [audioOptionType, setAudioOptionType] = useState<AudioOptionType>({
    inputOutputDeviceId: null,
  });
  const [rxEnabledMap, setRxEnabledMap] = useState<Map<string, boolean>>(
    new Map()
  );
  // STORES
  const outputDeviceSupported = useAudioOutputDeviceSupportedStore(
    (state) => state.supported
  );
  const audioUsageAllowed = useAudioUsageAllowedStore((state) => state.allowed);
  const rtcPeerActive = useRtcPeerConnectionStateStore((state) => state.active);
  const rtcPeerStop = useRtcPeerConnectionStateStore((state) => state.stop);
  const outputMediaDevices = useOutputMediaDevicesStore(
    (state) => state.outputMediaDevices
  );
  const setOutputMediaDevices = useOutputMediaDevicesStore(
    (state) => state.setOutputMediaDevices
  );
  const inboundStreams = useStreamsStore((state) => state.inboundStreams);
  const rxMonitors = useAudioMonitoringStore((state) => state.rxMonitors);

  // EFFECTS
  useEffect(() => {
    if (!audioUsageAllowed) {
      return;
    }

    function shouldUnsetOutputDeviceId() {
      return (
        outputMediaDevices.length === 0 &&
        audioOptionType.inputOutputDeviceId !== null
      );
    }

    function shouldSelectFirstOutputDeviceFromList() {
      return (
        audioOptionType.inputOutputDeviceId === null &&
        outputMediaDevices.length > 0 &&
        outputDeviceSupported
      );
    }

    function shouldSelectFirstOutputDeviceFromListWhenSelectedDoesNotFound() {
      return (
        audioOptionType.inputOutputDeviceId !== null &&
        outputMediaDevices.find(
          (value) => value.deviceId === audioOptionType.inputOutputDeviceId
        ) === undefined
      );
    }

    if (shouldUnsetOutputDeviceId()) {
      const newAudioOptionType = createNewAudioOptionType(audioOptionType);
      newAudioOptionType.inputOutputDeviceId = null;
      setAudioOptionType(newAudioOptionType);
      setOutputMediaDevices(outputMediaDevices);
    } else if (shouldSelectFirstOutputDeviceFromList()) {
      const newAudioOptionType = createNewAudioOptionType(audioOptionType);
      newAudioOptionType.inputOutputDeviceId = outputMediaDevices[0].deviceId;
      setAudioOptionType(newAudioOptionType);
      setOutputMediaDevices(outputMediaDevices);
    } else if (
      shouldSelectFirstOutputDeviceFromListWhenSelectedDoesNotFound()
    ) {
      const newAudioOptionType = createNewAudioOptionType(audioOptionType);
      newAudioOptionType.inputOutputDeviceId =
        outputMediaDevices.length > 0 ? outputMediaDevices[0].deviceId : null;
      setAudioOptionType(newAudioOptionType);
      setOutputMediaDevices(outputMediaDevices);
    }
  }, [
    outputMediaDevices,
    outputDeviceSupported,
    audioOptionType,
    audioOptionType.inputOutputDeviceId,
    audioUsageAllowed,
    setOutputMediaDevices,
  ]);

  useEffect(() => {
    if (monitoringConfiguration) {
      const currentRxDanteNameChannelsMap = new Map<
        string,
        ReceiveChannelConfigurationWithLink
      >();
      rxChannels.forEach((channel) =>
        currentRxDanteNameChannelsMap.set(channel.danteName, channel)
      );
      const selectedChannels = addLinkIdToReceivedChannelsWheneverPossible(
        monitoringConfiguration,
        currentRxDanteNameChannelsMap
      );
      notifyWhenConfigurationChangedAndRTCPeerActive(
        selectedChannels,
        currentRxDanteNameChannelsMap
      );
      handleLinkIdCleanupOnConfigurationChanges(
        selectedChannels,
        currentRxDanteNameChannelsMap
      );
      setRxChannels(selectedChannels);
      setUseRxDanteChannelNames(
        monitoringConfiguration.useRxDanteChannelNames ?? false
      );
      disableToggleOfNotExistingStreamsOrAddedChannelsAfterRTCPeerStarted(
        selectedChannels
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    monitoringConfiguration,
    monitoringConfiguration?.rxChannels?.length,
    monitoringConfiguration?.useRxDanteChannelNames,
  ]);

  useRxMakeAudioOffer(
    configurationId,
    rtcPeerActive,
    rtcConfiguration,
    rxChannels,
    audioOptionType.inputOutputDeviceId!
  );
  useStopAllRxAudio(!rtcPeerActive);
  useStopRxAudio(rtcPeerActive, rxChannels);

  useEffect(() => {
    if (!rtcPeerActive) {
      setRxEnabledMap(new Map());
    }
  }, [rtcPeerActive]);

  useEffect(() => {
    if (!audioUsageAllowed) {
      rtcPeerStop();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [audioUsageAllowed]);

  const notifyWhenConfigurationChangedAndRTCPeerActive = (
    selectedChannels: ReceiveChannelConfigurationWithLink[],
    currentRxDanteNameChannelsMap: Map<
      string,
      ReceiveChannelConfigurationWithLink
    >
  ) => {
    if (rtcPeerActive) {
      if (
        selectedChannels.length !== rxChannels.length ||
        selectedChannels.filter((channel) =>
          currentRxDanteNameChannelsMap.has(channel.danteName)
        ).length !== rxChannels.length
      ) {
        context.setContextualMessage({
          message: `Configuration of selected channels was modified. Please stop receiver to update state.                    
                    FROM: [${Array.from(
                      currentRxDanteNameChannelsMap.values()
                    ).map((channel) =>
                      !useRxDanteChannelNames && channel.userName
                        ? channel.userName
                        : channel.danteName
                    )}]                     
                    ON: [${selectedChannels.map((channel) =>
                      !useRxDanteChannelNames && channel.userName
                        ? channel.userName
                        : channel.danteName
                    )}].`,
          title: `Configuration changed.`,
        });
      }
    }
  };
  const handleLinkIdCleanupOnConfigurationChanges = (
    selectedChannels: ReceiveChannelConfigurationWithLink[],
    currentRxDanteNameChannelsMap: Map<
      string,
      ReceiveChannelConfigurationWithLink
    >
  ) => {
    for (let i = 0; i < selectedChannels.length; i++) {
      const linkId = selectedChannels[i].linkId;
      if (linkIdExistAndNextLinkIdIsDifferentThanCurrent(linkId, i)) {
        selectedChannels[i].linkId = null;
      } else if (
        linkIdExistAndItIsLatElementAndPreviousLinkIdIsDifferent(linkId, i)
      ) {
        selectedChannels[i].linkId = null;
      } else if (linkId !== null) {
        i++;
      }
    }

    function linkIdExistAndNextLinkIdIsDifferentThanCurrent(
      linkId: string | null,
      i: number
    ) {
      return (
        linkId !== null &&
        i < selectedChannels.length - 1 &&
        linkId !==
          (currentRxDanteNameChannelsMap.get(selectedChannels[i + 1].danteName)
            ?.linkId ?? null)
      );
    }

    function linkIdExistAndItIsLatElementAndPreviousLinkIdIsDifferent(
      linkId: string | null,
      i: number
    ) {
      return (
        linkId !== null &&
        i === selectedChannels.length - 1 &&
        linkId !==
          (currentRxDanteNameChannelsMap.get(selectedChannels[i - 1].danteName)
            ?.linkId ?? null)
      );
    }
  };

  const disableToggleOfNotExistingStreamsOrAddedChannelsAfterRTCPeerStarted = (
    selectedChannels: ReceiveChannelConfigurationWithLink[]
  ) => {
    selectedChannels.forEach((channel) => {
      if (rxEnabledMap.has(channelLinkIdOrDanteName(channel))) {
        if (
          inboundStreams.get(channelLinkIdOrDanteName(channel)) === undefined
        ) {
          setRxEnabledMap((prevState) =>
            prevState.set(channelLinkIdOrDanteName(channel), false)
          );
        }
        return;
      } else {
        setRxEnabledMap((prevState) =>
          prevState.set(channelLinkIdOrDanteName(channel), false)
        );
      }
    });
  };

  function addLinkIdToReceivedChannelsWheneverPossible(
    monitoringConfigurationData: MonitoringConfiguration,
    currentRxDanteNameChannelsMap: Map<
      string,
      ReceiveChannelConfigurationWithLink
    >
  ) {
    return (monitoringConfigurationData.rxChannels ?? [])
      .filter((channel) => channel.selected)
      .map((channel) => {
        return {
          danteName: channel.danteName,
          selected: channel.selected,
          __typename: channel.__typename,
          userName: channel.userName,
          linkId:
            currentRxDanteNameChannelsMap.get(channel.danteName)?.linkId ??
            null,
        } as ReceiveChannelConfigurationWithLink;
      });
  }

  const playRxLinkOrChannel = async (
    channel: ReceiveChannelConfigurationWithLink
  ) => {
    const monitor = rxMonitors.get(channelLinkIdOrDanteName(channel));
    const inboundStream = inboundStreams.get(channelLinkIdOrDanteName(channel));
    if (monitor && inboundStream) {
      await monitor.listen(inboundStream);
    } else {
      context.setError(`Unexpected error appeared when playing channel.`);
    }
  };

  const pauseRxLinkOrChannel = async (
    channel: ReceiveChannelConfigurationWithLink
  ) => {
    const monitor = rxMonitors.get(channelLinkIdOrDanteName(channel));
    const inboundStream = inboundStreams.get(channelLinkIdOrDanteName(channel));
    if (monitor && inboundStream) {
      await monitor.listen(null);
    } else {
      context.setError(`Unexpected error appeared when pausing channel.`);
    }
  };

  const onToggleChange =
    (channel: ReceiveChannelConfigurationWithLink) => async () => {
      const shouldPlay =
        !rxEnabledMap.get(channelLinkIdOrDanteName(channel)) ?? false;
      rxEnabledMap.set(channelLinkIdOrDanteName(channel), shouldPlay);
      setRxEnabledMap(rxEnabledMap);
      if (shouldPlay) {
        await playRxLinkOrChannel(channel);
      } else {
        await pauseRxLinkOrChannel(channel);
      }
    };

  function handleChangeOutputDevice(event: FormEvent<HTMLSelectElement>) {
    const newAudioOptionType: AudioOptionType =
      createNewAudioOptionType(audioOptionType);
    newAudioOptionType.inputOutputDeviceId = event.currentTarget.value;
    setAudioOptionType(newAudioOptionType);
  }

  const linkUnlinkChannels = (index: number) => {
    const topChannel = rxChannels[index];
    const bottomChannel = rxChannels[index + 1];
    if (bottomChannel.linkId === null && topChannel.linkId === null) {
      const linkId = uuidV4();
      topChannel.linkId = linkId;
      bottomChannel.linkId = linkId;
    } else if (bottomChannel.linkId !== null) {
      topChannel.linkId = null;
      bottomChannel.linkId = null;
    } else {
      context.setError(
        `Unexpected error occurs please contact system administrator.`
      );
    }
  };

  const displayLinkButton = (index: number) => {
    // at least 2 channels AND not last channel AND 2 corresponding channels same value of linkId
    return (
      rxChannels.length > 1 &&
      index >= 0 &&
      rxChannels.length > index + 1 &&
      rxChannels[index + 1].linkId === rxChannels[index].linkId
    );
  };

  const displayToggleButton = (index: number) => {
    // 1st item OR when previous not linked OR when corresponding links different
    return (
      index === 0 ||
      rxChannels[index - 1].linkId === null ||
      rxChannels[index - 1].linkId !== rxChannels[index].linkId
    );
  };

  return (
    <>
      <Table
        className={styles['monitoring-sites__table']}
        header={`Receive from:  ${deviceName}`}
      >
        <Table.Head>
          <Table.Row>
            <Table.Header className={styles['monitoring-sites__table-row']}>
              <span className={styles['monitoring-sites__span']}>
                Remote channels
              </span>
            </Table.Header>
            <Table.Header
              className={styles['monitoring-sites__cell-header-wide']}
            >
              {audioOptionType.inputOutputDeviceId !== '' &&
                outputDeviceSupported &&
                audioUsageAllowed && (
                  <Combobox
                    disabled={rtcPeerActive}
                    key={`combobox_key`}
                    options={outputMediaDevices}
                    onChange={handleChangeOutputDevice}
                  />
                )}
            </Table.Header>
          </Table.Row>
        </Table.Head>
        <Table.Body>
          {rxChannels.map((channel, index) => (
            <Table.Row
              key={`row_key_${index}${channel.danteName}`}
              className={styles['monitoring-sites__table-row']}
            >
              <Table.DataCell
                className={styles['monitoring-sites__cell-wide']}
                id="{channel_id}"
              >
                <span
                  className={styles['monitoring-sites__span']}
                  id={`remoteChannel-${index}-input`}
                >
                  {!useRxDanteChannelNames && channel.userName
                    ? channel.userName
                    : channel.danteName}
                </span>
              </Table.DataCell>
              <Table.DataCell id={`status-${'t43t'}`}>
                <span className={styles['monitoring-sites__arrow-icon']}>
                  <Arrow />
                </span>
              </Table.DataCell>
              <Table.DataCell
                className={styles['monitoring-sites__cell-wide']}
                id={`status-${'t43t'}`}
              >
                {displayLinkButton(index) && (
                  <div
                    className={classNames(
                      styles['monitoring-sites__link_button__position']
                    )}
                  >
                    <button
                      id={`rx-link-${index}-${channel.danteName}`}
                      disabled={rtcPeerActive}
                      className={classNames(
                        styles['monitoring-sites__link_button'],
                        rtcPeerActive
                          ? styles['monitoring-sites__link_button__disabled']
                          : channel.linkId !== null
                          ? styles['monitoring-sites__link_button__linked']
                          : styles['monitoring-sites__link_button__unlinked']
                      )}
                      onClick={() => {
                        linkUnlinkChannels(index);
                      }}
                    >
                      <img src={linkIcon} alt={''} width={15} height={15} />
                    </button>
                  </div>
                )}
              </Table.DataCell>
              <Table.DataCell
                className={styles['monitoring-sites__cell-wide']}
                id={`status-${'t43t'}`}
              >
                {displayToggleButton(index) && (
                  <ToggleSwitch
                    disabled={
                      !rtcPeerActive ||
                      !audioUsageAllowed ||
                      inboundStreams.get(channelLinkIdOrDanteName(channel)) ===
                        undefined
                    }
                    linked={channel.linkId !== null}
                    key={`toggle_switch_key_${index}-${channelLinkIdOrDanteName(
                      channel
                    )}`}
                    id={`danteChannel-rx-switch-${index}-${channel.danteName}`}
                    checked={
                      rxEnabledMap.get(channelLinkIdOrDanteName(channel)) ??
                      false
                    }
                    onChange={onToggleChange(channel)}
                  />
                )}
              </Table.DataCell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </>
  );
};

export default MonitoringReceivers;
