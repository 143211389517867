/*
 * File : Combobox.tsx
 * Created : March 2023
 * Authors :
 * Synopsis:
 *
 * Copyright 2023 Audinate Pty Ltd and/or its licensors
 *
 */
import classNames from 'classnames';
import styles from './Combobox.module.scss';
import React, { forwardRef } from 'react';

interface ComboboxProps extends React.HTMLAttributes<HTMLSelectElement> {
  options: MediaDeviceInfo[];
  className?: string;
  disabled?: boolean;
}

const Combobox = forwardRef<HTMLSelectElement, ComboboxProps>(
  ({ options, className: externalClassName, ...props }, ref) => {
    return (
      <div className={styles.combobox__select_wrapper}>
        <select
          disabled={props.disabled}
          className={classNames(styles.combobox__select_box, externalClassName)}
          {...props}
          ref={ref}
        >
          {options.map((selectOption, index) => (
            <option
              key={`${selectOption.deviceId}_${index}_${selectOption.label}`}
              value={selectOption.deviceId}
            >
              {selectOption.label}
            </option>
          ))}
        </select>
      </div>
    );
  }
);

export default Combobox;
