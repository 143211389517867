/*
 * File : ToggleSwitch.tsx
 * Created : May 2022
 * Authors :
 * Synopsis:
 *
 * Copyright 2022 Audinate Pty Ltd and/or its licensors
 *
 */
import styles from './ToggleSwitch.module.scss';
import { forwardRef } from 'react';
import classNames from 'classnames';

export interface ToggleSwitchProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  children?: string;
  hoverMessage?: string;
  checked: boolean;
  linked?: boolean;
}

const ToggleSwitch = forwardRef<HTMLInputElement, ToggleSwitchProps>(
  ({ checked, linked, hoverMessage, children, ...props }, ref) => {
    return (
      <span className={styles['toggle-switch__label']}>
        <label className={styles['toggle-switch__text']}>
          <span
            className={classNames(' ', {
              [styles['toggle-switch__tooltip']]: hoverMessage,
            })}
            data-hover={hoverMessage}
          >
            <label
              className={classNames(
                styles['toggle-switch'],
                linked ? styles['toggle-switch__linked'] : null
              )}
            >
              <input
                type="checkbox"
                checked={checked}
                className={styles.checkbox}
                ref={ref}
                {...props}
              />
              <span className={styles['toggle-switch__inside']} />
            </label>
          </span>
          {children}
        </label>
      </span>
    );
  }
);
export default ToggleSwitch;
