/*
 * File : socketProvider.ts
 * Created : March 2023
 * Authors :
 * Synopsis:
 *
 * Copyright 2023 Audinate Pty Ltd and/or its licensors
 *
 */
import io, { Socket } from 'socket.io-client';

// @ts-ignore
const configurationData = window['runtimeConfig'];
export const backendSignalingUri = configurationData.SIGNALING_SERVICE_ADDRESS
  ? configurationData.SIGNALING_SERVICE_ADDRESS
  : `http://localhost:8081`;

const createSocket = (): Socket => {
  return io(backendSignalingUri, {
    extraHeaders: {
      'x-dante-webrtc-type': 'peer',
    },
    path: `/signalling/socket.io`,
  });
};

export const socket = createSocket();
