/*
 * File : eventTypeEnum.ts
 * Created : March 2023
 * Authors :
 * Synopsis:
 *
 * Copyright 2023 Audinate Pty Ltd and/or its licensors
 *
 */
export enum EventTypeEnum {
  OFFER = 'offer',

  CANDIDATE = 'candidate',

  CONNECTION = 'connection',

  DISCONNECT = 'disconnect',

  BEFORE_UNLOAD = 'beforeunload',

  ANSWER = 'answer',

  OPEN = 'open',
}
