/*
 * File : audioService.ts
 * Created : March 2023
 * Authors :
 * Synopsis:
 *
 * Copyright 2023 Audinate Pty Ltd and/or its licensors
 *
 */
import { useContext, useEffect } from 'react';
import { AuthContext } from '../../context/authContext';
import {
  useAudioMonitoringStore,
  useRtcDataChannelsStore,
  useRTCPeerConnectionsStore,
  useStreamsStore,
} from '../store/monitoring';
import {
  ReceiveChannelConfigurationWithLink,
  TransmitChannelConfigurationWithLink,
} from '../types/audio';

export const useStopAllTxAudio = (execute: boolean) => {
  const context = useContext(AuthContext);
  const txRtcPeerConnections = useRTCPeerConnectionsStore(
    (state) => state.txRtcPeerConnections
  );
  const txMonitors = useAudioMonitoringStore((state) => state.txMonitors);
  const outboundStreams = useStreamsStore((state) => state.outboundStreams);
  const txRtcDataChannels = useRtcDataChannelsStore(
    (state) => state.txRtcDataChannels
  );
  const removeTxRtcPeerConnections = useRTCPeerConnectionsStore(
    (state) => state.removeTxRtcPeerConnections
  );
  const removeTxMonitors = useAudioMonitoringStore(
    (state) => state.removeTxMonitors
  );
  const removeOutboundStreams = useStreamsStore(
    (state) => state.removeOutboundStreams
  );
  const removeTxRtcDataChannels = useRtcDataChannelsStore(
    (state) => state.removeTxRtcDataChannels
  );

  useEffect(() => {
    if (execute && txRtcPeerConnections.size > 0) {
      txMonitors.forEach((monitor) =>
        monitor.listen(null).catch((err) => {
          console.error(err.message);
          context.setError(err.message);
        })
      );
      outboundStreams.forEach((stream) => {
        stream.getTracks().forEach((track) => track.stop());
      });
      txRtcDataChannels.forEach((dataChannel) => dataChannel.close());
      txRtcPeerConnections.forEach((connection) => connection.close());
      removeTxMonitors();
      removeOutboundStreams();
      removeTxRtcDataChannels();
      removeTxRtcPeerConnections();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [execute]); // use only this dependency
};

export const useStopAllRxAudio = (execute: boolean) => {
  const context = useContext(AuthContext);
  const rxRtcPeerConnections = useRTCPeerConnectionsStore(
    (state) => state.rxRtcPeerConnections
  );
  const rxMonitors = useAudioMonitoringStore((state) => state.rxMonitors);
  const inboundStreams = useStreamsStore((state) => state.inboundStreams);
  const rxRtcDataChannels = useRtcDataChannelsStore(
    (state) => state.rxRtcDataChannels
  );
  const removeRxRtcPeerConnections = useRTCPeerConnectionsStore(
    (state) => state.removeRxRtcPeerConnections
  );
  const removeRxMonitors = useAudioMonitoringStore(
    (state) => state.removeRxMonitors
  );
  const removeInboundStreams = useStreamsStore(
    (state) => state.removeInboundStreams
  );
  const removeRxRtcDataChannels = useRtcDataChannelsStore(
    (state) => state.removeRxRtcDataChannels
  );

  useEffect(() => {
    if (execute && rxRtcPeerConnections.size > 0) {
      rxMonitors.forEach((monitor) =>
        monitor.listen(null).catch((err) => {
          console.error(err.message);
          context.setError(err.message);
        })
      );
      inboundStreams.forEach((stream) => {
        stream.getTracks().forEach((track) => track.stop());
      });
      rxRtcDataChannels.forEach((dataChannel) => dataChannel.close());
      rxRtcPeerConnections.forEach((connection) => connection.close());
      removeRxMonitors();
      removeInboundStreams();
      removeRxRtcDataChannels();
      removeRxRtcPeerConnections();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [execute]); // use only this dependency
};

export const useStopRxAudio = (
  execute: boolean,
  rxDanteChannels: ReceiveChannelConfigurationWithLink[]
) => {
  const context = useContext(AuthContext);
  const rxRtcPeerConnections = useRTCPeerConnectionsStore(
    (state) => state.rxRtcPeerConnections
  );
  const rxMonitors = useAudioMonitoringStore((state) => state.rxMonitors);
  const inboundStreams = useStreamsStore((state) => state.inboundStreams);
  const rxRtcDataChannels = useRtcDataChannelsStore(
    (state) => state.rxRtcDataChannels
  );

  useEffect(() => {
    if (execute && rxRtcPeerConnections.size > 0) {
      const selectedChannels = new Set(
        rxDanteChannels.map((channel) => channel.linkId ?? channel.danteName)
      );
      // STOP ACTIVE STREAMS WHEN CHANNEL/LINK UNSELECTED
      const inboundChannelStreamsToBeStopped: string[] = [];
      Array.from(inboundStreams.keys()).forEach((key) => {
        if (!selectedChannels.has(key)) {
          inboundChannelStreamsToBeStopped.push(key);
        }
      });
      inboundChannelStreamsToBeStopped.forEach((linkIdOrDanteName) => {
        rxMonitors
          .get(linkIdOrDanteName)
          ?.listen(null)
          .catch((err) => {
            console.error(err.message);
            context.setError(err.message);
          });
        rxMonitors.delete(linkIdOrDanteName);
        inboundStreams
          .get(linkIdOrDanteName)
          ?.getTracks()
          .forEach((track) => track.stop());
        inboundStreams.delete(linkIdOrDanteName);
        rxRtcDataChannels.get(linkIdOrDanteName)?.close();
        rxRtcDataChannels.delete(linkIdOrDanteName);
        rxRtcPeerConnections.get(linkIdOrDanteName)?.close();
        rxRtcPeerConnections.delete(linkIdOrDanteName);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [execute, rxDanteChannels, rxDanteChannels.length]);
};

export const useStopTxAudio = (
  execute: boolean,
  txDanteChannels: TransmitChannelConfigurationWithLink[]
) => {
  const context = useContext(AuthContext);
  const txRtcPeerConnections = useRTCPeerConnectionsStore(
    (state) => state.txRtcPeerConnections
  );
  const rxMonitors = useAudioMonitoringStore((state) => state.rxMonitors);
  const outboundStreams = useStreamsStore((state) => state.outboundStreams);
  const txRtcDataChannels = useRtcDataChannelsStore(
    (state) => state.txRtcDataChannels
  );

  useEffect(() => {
    if (execute && txRtcPeerConnections.size > 0) {
      const selectedChannels = new Set(
        txDanteChannels.map((channel) => channel.linkId ?? channel.danteName)
      );
      // STOP ACTIVE STREAMS WHEN CHANNEL/LINK UNSELECTED
      const outboundChannelStreamsToBeStopped: string[] = [];
      Array.from(outboundStreams.keys()).forEach((key) => {
        if (!selectedChannels.has(key)) {
          outboundChannelStreamsToBeStopped.push(key);
        }
      });
      outboundChannelStreamsToBeStopped.forEach((linkIdOrDanteName) => {
        rxMonitors
          .get(linkIdOrDanteName)
          ?.listen(null)
          .catch((err) => {
            console.error(err.message);
            context.setError(err.message);
          });
        rxMonitors.delete(linkIdOrDanteName);
        outboundStreams
          .get(linkIdOrDanteName)
          ?.getTracks()
          .forEach((track) => track.stop());
        outboundStreams.delete(linkIdOrDanteName);
        txRtcDataChannels.get(linkIdOrDanteName)?.close();
        txRtcDataChannels.delete(linkIdOrDanteName);
        txRtcPeerConnections.get(linkIdOrDanteName)?.close();
        txRtcPeerConnections.delete(linkIdOrDanteName);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [execute, txDanteChannels, txDanteChannels.length]);
};
