/*
 * File : Monitoring.tsx
 * Created : April 2023
 * Authors :
 * Synopsis:
 *
 * Copyright 2023 Audinate Pty Ltd and/or its licensors
 *
 */
import Header from '../../components/Header';
import React, { lazy, useContext, useEffect, useMemo, useState } from 'react';
import styles from './Monitoring.module.scss';
import Button from 'src/components/Button';
import { AuthContext } from '../../context/authContext';
import {
  MonitoringConfiguration,
  RtcConfigurationJson,
  useGetMonitoringConfigurationByIdQuery,
  useGetRtcConfigurationQuery,
} from '../../graphql/graphqlGenerated';
import { GraphqlError } from '../../graphql/GraphqlError';
import { useLocation } from 'react-router-dom';
import Loading from '../../components/Loading';
import {
  useAudioOutputDeviceSupportedStore,
  useAudioUsageAllowedStore,
  useOutputMediaDevicesStore,
  useRtcPeerConnectionStateStore,
} from '../../ts/store/monitoring';
import {
  useAllowMediaDevices,
  useFetchDevices,
} from '../../ts/services/deviceService';
import AudioMonitoring from '../../ts/classes/AudioMonitoring';
import { AppTypeEnum, isAppType } from '../../ts/enums/appTypeEnum';
import MonitoringReceivers from './MonitoringReceivers';
import classNames from 'classnames';

const MonitoringSenders = lazy(() => import('./MonitoringSenders'));

interface MonitoringProps {
  deviceName: string;
}

const Monitoring = ({ deviceName }: MonitoringProps) => {
  // STATES
  const configurationId = useLocation().pathname.split('/')[2];
  const context = useContext(AuthContext);
  const [monitoringConfiguration, setMonitoringConfiguration] =
    useState<MonitoringConfiguration | null>(null);
  const [rtcConfiguration, setRtcConfiguration] =
    useState<RTCConfiguration | null>(null);
  // STORES
  const audioUsageAllowed = useAudioUsageAllowedStore((state) => state.allowed);
  const rtcPeerActive = useRtcPeerConnectionStateStore((state) => state.active);
  const rtcPeerStart = useRtcPeerConnectionStateStore((state) => state.start);
  const rtcPeerStop = useRtcPeerConnectionStateStore((state) => state.stop);
  const outputDeviceSupported = useAudioOutputDeviceSupportedStore(
    (state) => state.supported
  );
  const outputMediaDevices = useOutputMediaDevicesStore(
    (state) => state.outputMediaDevices
  );

  useEffect(() => {
    if (
      audioUsageAllowed &&
      !AudioMonitoring.doesBrowserSupportOutputDevice()
    ) {
      context.setWarning(
        'Browser does not support selecting output device - using default system selected.'
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [audioUsageAllowed]);

  // EFFECTS
  useEffect(() => {
    if (!audioUsageAllowed) {
      rtcPeerStop();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [audioUsageAllowed]);

  useAllowMediaDevices(isAppType(AppTypeEnum.MONITORING));
  useFetchDevices();

  const { loading: configurationLoading } =
    useGetMonitoringConfigurationByIdQuery({
      variables: {
        monitoringConfigurationId: configurationId,
      },
      onError: (error) => {
        context.setError(error.message);
        setMonitoringConfiguration(null);
      },
      onCompleted: (data) => {
        if (data?.monitoringConfiguration.__typename?.endsWith('Error')) {
          context.setError(
            (data.monitoringConfiguration as GraphqlError).message
          );
          setMonitoringConfiguration(null);
        } else {
          const monitoringConfigurationData =
            data.monitoringConfiguration as MonitoringConfiguration;
          setMonitoringConfiguration(monitoringConfigurationData);
        }
      },
      pollInterval: 5000,
      errorPolicy: 'all',
    });

  const { loading: rtcConfigurationLoading } = useGetRtcConfigurationQuery({
    onError: (error) => {
      context.setError(error.message);
      setRtcConfiguration(null);
    },
    onCompleted: (data) => {
      if (data?.rtcConfiguration.__typename?.endsWith('Error')) {
        context.setError((data.rtcConfiguration as GraphqlError).message);
        setRtcConfiguration(null);
      } else {
        try {
          const rtcConfigurationJson =
            data.rtcConfiguration as RtcConfigurationJson;
          const rtcConfiguration = JSON.parse(
            rtcConfigurationJson.json
          ) as RTCConfiguration;
          setRtcConfiguration(rtcConfiguration);
        } catch (err) {
          if (err instanceof Error) {
            console.debug(err.message);
            context.setError(err.message);
          } else {
            console.debug(
              `Unexpected error occurred when fetching RTC Configuration`
            );
            context.setError(
              `Unexpected error occurred when fetching RTC Configuration`
            );
          }
          setRtcConfiguration(null);
        }
      }
    },
  });

  const loadingState = useMemo(
    () => configurationLoading || rtcConfigurationLoading,
    [configurationLoading, rtcConfigurationLoading]
  );

  if (loadingState) {
    return <Loading />;
  }

  const changeRtcPeerButtonState = () => {
    if (rtcPeerActive) {
      rtcPeerStop();
    } else {
      rtcPeerStart();
    }
  };

  function disableStartStopMonitoringButton() {
    return (
      (isAppType(AppTypeEnum.CONTRIBUTION) &&
        monitoringConfiguration?.clientConnected &&
        !rtcPeerActive) ||
      (outputDeviceSupported && outputMediaDevices.length === 0) ||
      rtcConfiguration === null ||
      !audioUsageAllowed ||
      ((monitoringConfiguration?.rxChannels?.length ?? 0) === 0 &&
        (monitoringConfiguration?.txChannels?.length ?? 0) === 0)
    );
  }

  return (
    <div className={classNames(styles.main)}>
      <Header
        deviceName={'Dante Remote Client'}
        domainName={''}
        adminRoute={false}
      />
      {monitoringConfiguration && (
        <main className={styles['monitoring-sites']}>
          <div className={styles['monitoring-sites__title-container']}>
            <div className={styles['monitoring-sites__title']}>
              Remote to: {deviceName}
            </div>
            <div className={styles['monitoring-sites__status_info_text']}>
              <p id={'dante-status-header'}>
                {`Status: ${
                  rtcPeerActive
                    ? 'Started'
                    : isAppType(AppTypeEnum.CONTRIBUTION) &&
                      monitoringConfiguration.clientConnected
                    ? 'Busy'
                    : 'Ready to start'
                }`}
              </p>
              <p style={{ paddingLeft: '15px' }} />
              <Button
                id={'rtc-start-stop-button'}
                variant={'primary'}
                disabled={disableStartStopMonitoringButton()}
                onClick={changeRtcPeerButtonState}
              >
                {rtcPeerActive ? 'Stop' : 'Start'}
              </Button>
            </div>
          </div>
          <MonitoringReceivers
            configurationId={configurationId}
            deviceName={deviceName}
            rtcConfiguration={rtcConfiguration}
            monitoringConfiguration={monitoringConfiguration}
          />
          {isAppType(AppTypeEnum.CONTRIBUTION) && (
            <MonitoringSenders
              configurationId={configurationId}
              deviceName={deviceName}
              rtcConfiguration={rtcConfiguration}
              monitoringConfiguration={monitoringConfiguration}
            />
          )}
        </main>
      )}
    </div>
  );
};

export default Monitoring;
