/*
 * File : monitoring.ts
 * Created : March 2023
 * Authors :
 * Synopsis:
 *
 * Copyright 2023 Audinate Pty Ltd and/or its licensors
 *
 */
import { create } from 'zustand';
import AudioMonitoring from '../classes/AudioMonitoring';

type MediaDevicesState = {
  mediaDevices: MediaDeviceInfo[];
  setMediaDevices: (state: MediaDeviceInfo[]) => void;
  removeAllMediaDevices: () => void;
};
export const useAllMediaDevicesStore = create<MediaDevicesState>((set) => ({
  mediaDevices: [],
  setMediaDevices: (state: MediaDeviceInfo[]) => set({ mediaDevices: state }),
  removeAllMediaDevices: () => set({ mediaDevices: [] }),
}));

type AudioUsageAllowedState = {
  allowed: boolean;
  allow: () => void;
  disallow: () => void;
};
export const useAudioUsageAllowedStore = create<AudioUsageAllowedState>(
  (set) => ({
    allowed: false,
    allow: () => set({ allowed: true }),
    disallow: () => set({ allowed: false }),
  })
);

type AudioOutputDeviceSupportedState = {
  supported: boolean;
};
export const useAudioOutputDeviceSupportedStore =
  create<AudioOutputDeviceSupportedState>(() => ({
    supported: AudioMonitoring.doesBrowserSupportOutputDevice(),
  }));

type OutputMediaDevicesState = {
  outputMediaDevices: MediaDeviceInfo[];
  setOutputMediaDevices: (state: MediaDeviceInfo[]) => void;
  removeAllOutputMediaDevices: () => void;
};
export const useOutputMediaDevicesStore = create<OutputMediaDevicesState>(
  (set) => ({
    outputMediaDevices: [],
    setOutputMediaDevices: (state: MediaDeviceInfo[]) =>
      set({ outputMediaDevices: state }),
    removeAllOutputMediaDevices: () => set({ outputMediaDevices: [] }),
  })
);

type InputMediaDevicesState = {
  inputMediaDevices: MediaDeviceInfo[];
  setInputMediaDevices: (state: MediaDeviceInfo[]) => void;
  removeAllInputMediaDevices: () => void;
};
export const useInputMediaDevicesStore = create<InputMediaDevicesState>(
  (set) => ({
    inputMediaDevices: [],
    setInputMediaDevices: (state: MediaDeviceInfo[]) =>
      set({ inputMediaDevices: state }),
    removeAllInputMediaDevices: () => set({ inputMediaDevices: [] }),
  })
);

type StreamsState = {
  inboundStreams: Map<string, MediaStream>;
  outboundStreams: Map<string, MediaStream>;
  addInboundStream: (rx: string, stream: MediaStream) => void;

  addOutboundStream: (tx: string, stream: MediaStream) => void;
  removeInboundStreams: () => void;

  removeOutboundStreams: () => void;
};
export const useStreamsStore = create<StreamsState>((set) => ({
  inboundStreams: new Map<string, MediaStream>(),
  outboundStreams: new Map<string, MediaStream>(),
  addInboundStream: (rx: string, stream: MediaStream) =>
    set((state) => ({ inboundStreams: state.inboundStreams.set(rx, stream) })),
  addOutboundStream: (tx: string, stream: MediaStream) =>
    set((state) => ({
      outboundStreams: state.outboundStreams.set(tx, stream),
    })),
  removeInboundStreams: () => set({ inboundStreams: new Map() }),
  removeOutboundStreams: () => set({ outboundStreams: new Map() }),
}));

type RtcDataChannelsState = {
  rxRtcDataChannels: Map<string, RTCDataChannel>;
  txRtcDataChannels: Map<string, RTCDataChannel>;
  addRxRtcDataChannel: (rx: string, channel: RTCDataChannel) => void;
  addTxRtcDataChannel: (tx: string, channel: RTCDataChannel) => void;
  removeRxRtcDataChannels: () => void;
  removeTxRtcDataChannels: () => void;
};
export const useRtcDataChannelsStore = create<RtcDataChannelsState>((set) => ({
  rxRtcDataChannels: new Map<string, RTCDataChannel>(),
  txRtcDataChannels: new Map<string, RTCDataChannel>(),
  addRxRtcDataChannel: (rx: string, channel: RTCDataChannel) =>
    set((state) => ({
      rxRtcDataChannels: state.rxRtcDataChannels.set(rx, channel),
    })),
  addTxRtcDataChannel: (tx: string, channel: RTCDataChannel) =>
    set((state) => ({
      txRtcDataChannels: state.txRtcDataChannels.set(tx, channel),
    })),
  removeRxRtcDataChannels: () => set({ rxRtcDataChannels: new Map() }),

  removeTxRtcDataChannels: () => set({ txRtcDataChannels: new Map() }),
}));

type RtcPeerConnectionsStoreState = {
  rxRtcPeerConnections: Map<string, RTCPeerConnection>;
  addRxRtcPeerConnection: (rx: string, connection: RTCPeerConnection) => void;

  txRtcPeerConnections: Map<string, RTCPeerConnection>;
  addTxRtcPeerConnection: (tx: string, connection: RTCPeerConnection) => void;
  removeTxRtcPeerConnections: () => void;

  removeRxRtcPeerConnections: () => void;
};
export const useRTCPeerConnectionsStore = create<RtcPeerConnectionsStoreState>(
  (set) => ({
    rxRtcPeerConnections: new Map<string, RTCPeerConnection>(),
    txRtcPeerConnections: new Map<string, RTCPeerConnection>(),
    addRxRtcPeerConnection: (rx: string, connection: RTCPeerConnection) =>
      set((state) => ({
        rxRtcPeerConnections: state.rxRtcPeerConnections.set(rx, connection),
      })),

    addTxRtcPeerConnection: (tx: string, connection: RTCPeerConnection) =>
      set((state) => ({
        txRtcPeerConnections: state.txRtcPeerConnections.set(tx, connection),
      })),
    removeRxRtcPeerConnections: () => set({ rxRtcPeerConnections: new Map() }),

    removeTxRtcPeerConnections: () => set({ txRtcPeerConnections: new Map() }),
  })
);

type AudioMonitoringState = {
  rxMonitors: Map<string, AudioMonitoring>;
  txMonitors: Map<string, AudioMonitoring>;
  addRxMonitors: (rx: string, monitor: AudioMonitoring) => void;
  addTxMonitors: (tx: string, monitor: AudioMonitoring) => void;
  removeTxMonitors: () => void;
  removeRxMonitors: () => void;
};
export const useAudioMonitoringStore = create<AudioMonitoringState>((set) => ({
  rxMonitors: new Map<string, AudioMonitoring>(),
  txMonitors: new Map<string, AudioMonitoring>(),
  addRxMonitors: (rx: string, monitor: AudioMonitoring) =>
    set((state) => ({ rxMonitors: state.rxMonitors.set(rx, monitor) })),
  addTxMonitors: (tx: string, monitor: AudioMonitoring) =>
    set((state) => ({ txMonitors: state.txMonitors.set(tx, monitor) })),
  removeTxMonitors: () => set({ txMonitors: new Map() }),
  removeRxMonitors: () => set({ rxMonitors: new Map() }),
}));

type RtcPeerConnectionState = {
  active: boolean;
  start: () => void;
  stop: () => void;
};
export const useRtcPeerConnectionStateStore = create<RtcPeerConnectionState>(
  (set) => ({
    active: false,
    start: () => set({ active: true }),
    stop: () => set({ active: false }),
  })
);
