/*
 * File : offerService.ts
 * Created : April 2023
 * Authors :
 * Synopsis:
 *
 * Copyright 2023 Audinate Pty Ltd and/or its licensors
 *
 */
import { useContext, useEffect } from 'react';
import {
  useAudioMonitoringStore,
  useRtcDataChannelsStore,
  useRTCPeerConnectionsStore,
  useRtcPeerConnectionStateStore,
  useStreamsStore,
} from '../store/monitoring';
import AudioMonitoring from '../classes/AudioMonitoring';
import { socket } from '../socketProvider';
import { AnswerPayload, OfferPayload } from '../types/offer';
import { AuthContext, AuthContextType } from '../../context/authContext';
import { EventTypeEnum } from '../enums/eventTypeEnum';
import { ConnectionStateEnum } from '../enums/connectionStateEnum';
import {
  ReceiveChannelConfigurationWithLink,
  TransmitChannelConfigurationWithLink,
} from '../types/audio';
import { channelLinkIdOrDanteName } from '../helpers/audio';

export const useRxMakeAudioOffer = (
  configurationId: string | null,
  execute: boolean,
  rtcConfiguration: RTCConfiguration | null,
  rxChannels: ReceiveChannelConfigurationWithLink[],
  outputDeviceId: string
) => {
  const context = useContext(AuthContext);
  const addRxRtcPeerConnection = useRTCPeerConnectionsStore(
    (state) => state.addRxRtcPeerConnection
  );
  const addRxMonitors = useAudioMonitoringStore((state) => state.addRxMonitors);
  const addInboundStream = useStreamsStore((state) => state.addInboundStream);
  const addRxRtcDataChannel = useRtcDataChannelsStore(
    (state) => state.addRxRtcDataChannel
  );
  const rtcPeerStop = useRtcPeerConnectionStateStore((state) => state.stop);

  useEffect(() => {
    const makeOffer = async (
      linkIdOrDanteName: string,
      groupedRxChannels: ReceiveChannelConfigurationWithLink[]
    ) => {
      let connectionState = '';
      const RTCPeer = new RTCPeerConnection(rtcConfiguration ?? undefined);
      addRxRtcPeerConnection(linkIdOrDanteName, RTCPeer);
      const monitor = await setOutputDeviceId(outputDeviceId);
      addRxMonitors(linkIdOrDanteName, monitor);

      closeRTCPeerOnTabClose(RTCPeer);
      getOwnIceCandidateDetails(RTCPeer, context);
      handleSocketOnCandidateReceive(RTCPeer);
      await handleConnectionStateChanges();
      RTCPeer.addTransceiver('audio', { direction: 'recvonly' });

      // @ts-ignore
      window[`RTCPeer${linkIdOrDanteName}`] = RTCPeer;
      createDataChannel();
      await createRTCPeerReceiveOffer(RTCPeer);
      socketEmitOffer();
      connectionState = 'Waiting for answer';
      console.debug(connectionState);

      function handleConnectionStateChanges() {
        function handleStateChange(this: RTCPeerConnection) {
          const connState = this.connectionState || this.iceConnectionState;
          let resolvedChannelName = groupedRxChannels.map((channel) =>
            channel.userName !== null && channel.userName !== ''
              ? channel.userName
              : channel.danteName
          );
          console.debug(
            `Connection state changed on: ${connState} for ${resolvedChannelName}.`
          );

          if (connState === ConnectionStateEnum.CLOSED) {
            context.setError(
              `RTCPeer connection for channel ${resolvedChannelName} was closed.`
            );
            rtcPeerStop();
          }

          if (connState === ConnectionStateEnum.DISCONNECTED) {
            context.setError(
              `Disconnected RTCPeer connection for channel ${resolvedChannelName}.`
            );
            rtcPeerStop();
          }

          if (connState === ConnectionStateEnum.FAILED) {
            context.setError(
              `RTCPeer connection for channel ${resolvedChannelName} failed.`
            );
            rtcPeerStop();
          }

          if (connState === ConnectionStateEnum.CONNECTED) {
            let receivers = RTCPeer.getReceivers();
            const stream = createMediaStream(receivers[0].track);
            monitor.addStream(stream);
            addInboundStream(linkIdOrDanteName, stream);
          }
        }

        RTCPeer.addEventListener('iceconnectionstatechange', handleStateChange);
        RTCPeer.addEventListener('connectionstatechange', handleStateChange);
      }

      function createDataChannel() {
        const dataChannel = RTCPeer.createDataChannel(
          `KEEP-ALIVE_RX-${linkIdOrDanteName}_DEVICE-${outputDeviceId} `,
          {
            ordered: false,
          }
        );
        let intervalId: NodeJS.Timer;
        dataChannel.addEventListener(EventTypeEnum.OPEN, function () {
          console.debug('Keep-Alive channel established');
          let KA = new Uint8Array([0xff]);
          intervalId = setInterval(
            () =>
              ['open', 'connecting'].includes(dataChannel.readyState)
                ? this.send(KA)
                : () => this.close(),
            1000
          );
        });

        dataChannel.onclose = function (ev) {
          ev.stopImmediatePropagation();
          clearInterval(intervalId);
        };

        addRxRtcDataChannel(linkIdOrDanteName, dataChannel);
      }

      function socketEmitOffer() {
        socket.emit(
          EventTypeEnum.OFFER,
          {
            sdp: RTCPeer.localDescription?.sdp,
            rx: groupedRxChannels.map((channel) => channel.danteName),
            tx: [],
            configurationId: configurationId,
          } as OfferPayload,
          function (answer: AnswerPayload) {
            connectionState = 'Got answer';
            if (answer.error) {
              connectionState = 'Error: ' + answer.message;
              context.setError(answer.message);
              rtcPeerStop();
              return;
            }

            if (RTCPeer.remoteDescription) {
              console.warn(
                'Got SDP answer even though the remote description was already set'
              );
              return;
            }

            console.debug('Got SDP answer', answer.sdp);

            RTCPeer.setRemoteDescription({
              type: EventTypeEnum.ANSWER,
              sdp: answer.sdp,
            } as RTCSessionDescriptionInit).catch((e) => {
              connectionState = 'Error: ' + e;
              context.setError(connectionState);
              console.error(e);
              rtcPeerStop();
            });
          }
        );
      }

      return () => {
        socket.off(EventTypeEnum.CANDIDATE);
        socket.off(EventTypeEnum.OFFER);
      };
    };

    const makeOfferPerEachChannel = async () => {
      const groupedByLinkIdOrDanteName = rxChannels.reduce(function (r, a) {
        r[channelLinkIdOrDanteName(a)] = r[channelLinkIdOrDanteName(a)] || [];
        r[channelLinkIdOrDanteName(a)].push(a);
        return r;
      }, Object.create(null));
      for (const key of Object.keys(groupedByLinkIdOrDanteName)) {
        await makeOffer(
          key,
          groupedByLinkIdOrDanteName[
            key
          ] as ReceiveChannelConfigurationWithLink[]
        ).catch((err) => {
          console.error(err);
          context.setError(
            'Unexpected error occurred when creating Peer Connection. Please retry or contact system administrator.'
          );
          rtcPeerStop();
        });
      }
    };

    if (execute && rtcConfiguration !== null && configurationId !== null) {
      makeOfferPerEachChannel().catch((err) => {
        console.error(err);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [execute]); // use only this dependency
};

export const useTxMakeAudioOffer = (
  configurationId: string | null,
  execute: boolean,
  rtcConfiguration: RTCConfiguration | null,
  txChannels: TransmitChannelConfigurationWithLink[],
  inputDeviceId: string
) => {
  const context = useContext(AuthContext);
  const addTxRtcPeerConnection = useRTCPeerConnectionsStore(
    (state) => state.addTxRtcPeerConnection
  );
  const addTxMonitors = useAudioMonitoringStore((state) => state.addTxMonitors);
  const addOutboundStream = useStreamsStore((state) => state.addOutboundStream);
  const addTxRtcDataChannel = useRtcDataChannelsStore(
    (state) => state.addTxRtcDataChannel
  );
  const rtcPeerStop = useRtcPeerConnectionStateStore((state) => state.stop);

  useEffect(() => {
    const makeOffer = async (
      linkIdOrDanteName: string,
      groupedTxChannels: TransmitChannelConfigurationWithLink[]
    ) => {
      let connectionState = '';
      const RTCPeer = new RTCPeerConnection(rtcConfiguration ?? undefined);
      const monitor = new AudioMonitoring();
      addTxMonitors(linkIdOrDanteName, monitor);

      closeRTCPeerOnTabClose(RTCPeer);
      getOwnIceCandidateDetails(RTCPeer, context);
      handleSocketOnCandidateReceive(RTCPeer);
      await handleConnectionStateChanges();
      await setUpMicrophoneDevice(RTCPeer);

      // @ts-ignore
      window[`RTCPeer${linkIdOrDanteName}`] = RTCPeer;
      createDataChannel();
      await createRTCPeerTransmitOffer(RTCPeer, groupedTxChannels.length > 1);
      socketEmitOffer();
      connectionState = 'Waiting for answer';
      console.debug(connectionState);

      async function setUpMicrophoneDevice(RTCPeer: RTCPeerConnection) {
        let stream = await navigator.mediaDevices.getUserMedia({
          audio: {
            autoGainControl: false,
            echoCancellation: false,
            latency: 0,
            noiseSuppression: false,
            sampleRate: 48000,
            sampleSize: 16,
          },
        });

        let tracks = stream.getAudioTracks();
        if (tracks.length === 0) {
          context.setWarning('No source audio tracks found');
          rtcPeerStop();
        }

        if (tracks.length > 1) {
          console.warn('More than one source track found', tracks);
        } else {
          console.debug('Available mic tracks:', tracks);
        }

        console.debug('Adding audio track', tracks[0]);
        RTCPeer.addTrack(tracks[0]);

        // @ts-ignore
        window[`RTCPeer${linkIdOrDanteName}`] = RTCPeer;

        const outboundStream = createMediaStream(tracks[0]);
        outboundStream
          .getAudioTracks()
          .forEach((track) => (track.enabled = false));
        monitor.addStream(outboundStream);
        addOutboundStream(linkIdOrDanteName, outboundStream);
      }

      function handleConnectionStateChanges() {
        function handleStateChange(this: RTCPeerConnection) {
          const connState = this.connectionState || this.iceConnectionState;
          let resolvedChannelName = groupedTxChannels.map((channel) =>
            channel.userName !== null && channel.userName !== ''
              ? channel.userName
              : channel.danteName
          );
          console.debug(
            `Connection state changed on: ${connState} for ${resolvedChannelName}.`
          );

          if (connState === ConnectionStateEnum.CLOSED) {
            context.setError(
              `RTCPeer connection for channel ${resolvedChannelName} was closed.`
            );
            rtcPeerStop();
          }

          if (connState === ConnectionStateEnum.DISCONNECTED) {
            context.setError(
              `Disconnected RTCPeer connection for channel ${resolvedChannelName}.`
            );
            rtcPeerStop();
          }

          if (connState === ConnectionStateEnum.FAILED) {
            context.setError(
              `RTCPeer connection for channel ${resolvedChannelName} failed.`
            );
            rtcPeerStop();
          }

          if (connState === ConnectionStateEnum.CONNECTED) {
            addTxRtcPeerConnection(linkIdOrDanteName, RTCPeer);
          }
        }

        RTCPeer.addEventListener('iceconnectionstatechange', handleStateChange);
        RTCPeer.addEventListener('connectionstatechange', handleStateChange);
      }

      function createDataChannel() {
        const dataChannel = RTCPeer.createDataChannel(
          `KEEP-ALIVE_TX-${linkIdOrDanteName}_DEVICE-${inputDeviceId} `,
          {
            ordered: false,
          }
        );
        let intervalId: NodeJS.Timer;
        dataChannel.addEventListener(EventTypeEnum.OPEN, function () {
          console.debug('Keep-Alive channel established');
          let KA = new Uint8Array([0xff]);
          intervalId = setInterval(
            () =>
              ['open', 'connecting'].includes(dataChannel.readyState)
                ? this.send(KA)
                : this.close(),
            1000
          );
        });

        dataChannel.onclose = function (ev) {
          ev.stopImmediatePropagation();
          clearInterval(intervalId);
        };

        addTxRtcDataChannel(linkIdOrDanteName, dataChannel);
      }

      function socketEmitOffer() {
        socket.emit(
          EventTypeEnum.OFFER,
          {
            sdp: RTCPeer.localDescription?.sdp,
            rx: [],
            tx: groupedTxChannels.map((channel) => channel.danteName),
            configurationId: configurationId,
          } as OfferPayload,
          function (answer: AnswerPayload) {
            connectionState = 'Got answer';
            if (answer.error) {
              connectionState = 'Error: ' + answer.message;
              context.setError(answer.message);
              rtcPeerStop();
              return;
            }

            if (RTCPeer.remoteDescription) {
              console.warn(
                'Got SDP answer even though the remote description was already set'
              );
              return;
            }

            console.debug('Got SDP answer', answer.sdp);

            RTCPeer.setRemoteDescription({
              type: EventTypeEnum.ANSWER,
              sdp: answer.sdp,
            } as RTCSessionDescriptionInit).catch((e) => {
              connectionState = 'Error: ' + e;
              context.setError(connectionState);
              console.error(e);
              rtcPeerStop();
            });
          }
        );
      }

      return () => {
        socket.off(EventTypeEnum.CANDIDATE);
        socket.off(EventTypeEnum.OFFER);
      };
    };

    const makeOfferPerEachChannel = async () => {
      const groupedByLinkIdOrDanteName = txChannels.reduce(function (r, a) {
        r[channelLinkIdOrDanteName(a)] = r[channelLinkIdOrDanteName(a)] || [];
        r[channelLinkIdOrDanteName(a)].push(a);
        return r;
      }, Object.create(null));
      for (const key of Object.keys(groupedByLinkIdOrDanteName)) {
        await makeOffer(
          key,
          groupedByLinkIdOrDanteName[
            key
          ] as TransmitChannelConfigurationWithLink[]
        ).catch((err) => {
          console.error(err);
        });
      }
    };

    if (execute && rtcConfiguration !== null && configurationId !== null) {
      makeOfferPerEachChannel().catch((err) => {
        console.error(err);
        context.setError(
          'Unexpected error occurred when creating Peer Connection. Please retry or contact system administrator.'
        );
        rtcPeerStop();
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [execute]); // use only this dependency
};

async function setOutputDeviceId(
  outputDeviceId: string
): Promise<AudioMonitoring> {
  const monitor = new AudioMonitoring();
  await monitor.setOutputDevice(outputDeviceId);
  console.debug('Output device changed to ', outputDeviceId);
  return monitor;
}

function closeRTCPeerOnTabClose(RTCPeer: RTCPeerConnection) {
  /**
   * On Google Chrome, if multiple sessions are opened across multiple tabs,
   * closing the tabs does not trigger the disconnect event - So manually do it
   */
  window.addEventListener(EventTypeEnum.BEFORE_UNLOAD, function () {
    RTCPeer.close();
  });
}

function getOwnIceCandidateDetails(
  RTCPeer: RTCPeerConnection,
  context: AuthContextType
) {
  // Receive your own candidate information
  RTCPeer.onicecandidate = function (evt) {
    if (!evt.candidate?.candidate) return;

    console.debug('Gathered local candidate: ', evt.candidate);

    if (evt.candidate.candidate.includes('ufrag')) {
      socket.emit(EventTypeEnum.CANDIDATE, evt.candidate.candidate);
    } else if (evt.candidate.usernameFragment !== null) {
      socket.emit(EventTypeEnum.CANDIDATE, evt.candidate);
    } else {
      context.setError(
        'Cannot exchange candidates. Please contact system administrator.'
      );
    }
  };
}

function handleSocketOnCandidateReceive(RTCPeer: RTCPeerConnection) {
  function onCandidateReceive(candidateData: string) {
    if ((RTCPeer.connectionState || RTCPeer.iceConnectionState) === 'closed') {
      console.debug(
        'Dropped remote candidate as connection was already closed.'
      );
      return;
    }

    if (!RTCPeer.remoteDescription) {
      // Got a remote candidate but was not ready yet, try again
      setTimeout(() => onCandidateReceive(candidateData), 500);
      return;
    }
    if (
      (RTCPeer.connectionState || RTCPeer.iceConnectionState) === 'connected'
    ) {
      console.debug(
        'Dropped remote candidate as connection was already established.'
      );
      return;
    }

    console.debug('Received remote candidate: ', candidateData);
    let idx = candidateData.indexOf(':');

    RTCPeer.addIceCandidate({
      candidate: candidateData.slice(idx + 1),
      sdpMid: candidateData.slice(0, idx),
    } as RTCIceCandidate).then(() => {});
  }

  socket.on(EventTypeEnum.CANDIDATE, onCandidateReceive);
}

async function createRTCPeerReceiveOffer(RTCPeer: RTCPeerConnection) {
  await RTCPeer.createOffer().then((offer) => {
    offer.sdp = offer.sdp?.replace('useinbandfec=1', 'useinbandfec=1;stereo=1');
    console.debug('Created OFFER: ' + offer.sdp);
    return RTCPeer.setLocalDescription(offer);
  });
}

async function createRTCPeerTransmitOffer(
  RTCPeer: RTCPeerConnection,
  stereo: boolean
) {
  await RTCPeer.createOffer().then((offer) => {
    if (stereo) {
      offer.sdp = offer.sdp?.replace(
        'useinbandfec=1',
        'useinbandfec=1;stereo=1'
      );
    }
    console.debug('Created OFFER: ' + offer.sdp);
    return RTCPeer.setLocalDescription(offer);
  });
}

function createMediaStream(track: MediaStreamTrack) {
  let stream = new MediaStream();
  stream.addTrack(track);
  return stream;
}
