/*
 * File : functions.tsx
 * Created : March 2023
 * Authors :
 * Synopsis:
 *
 * Copyright 2023 Audinate Pty Ltd and/or its licensors
 *
 */
import { BrowserRouter } from 'react-router-dom';
import React from 'react';
import { ApolloProvider } from '@apollo/client';
import client from '../apolloClient';

export function testComponentWrapper(children: JSX.Element) {
  return <BrowserRouter>{children}</BrowserRouter>;
}

export function apolloComponentWrapper(children: JSX.Element) {
  return <ApolloProvider client={client}>{children}</ApolloProvider>;
}

// 3600000 mili-seconds for an hour
const ttl = 3600000 * 24 * 7;
const loginTokenKey = 'danteConnectToken';
export function recordTokenToLocalStorage(token: string) {
  const now = new Date();

  // `item` is an object which contains the original value
  // as well as the time when it's supposed to expire
  const item = {
    value: token,
    expiry: now.getTime() + ttl,
  };
  localStorage.setItem(loginTokenKey, JSON.stringify(item));
  window.dispatchEvent(new Event('storage'));
}

export function getUserLoginInfo() {
  const itemStr = localStorage.getItem(loginTokenKey);
  // if the item doesn't exist, return null
  if (!itemStr) {
    return undefined;
  }
  const item = JSON.parse(itemStr);
  const now = new Date();
  // compare the expiry time of the item with the current time
  if (
    now.getTime() > item.expiry ||
    !item?.value ||
    typeof item.value !== 'string'
  ) {
    // If the item is expired, delete the item from storage
    // and return null
    localStorage.removeItem(loginTokenKey);
    return undefined;
  }
  return item.value as string;
}

export function userLogoutAction() {
  console.log('logout action called');
  localStorage.removeItem(loginTokenKey);
  window.dispatchEvent(new Event('storage'));
}
