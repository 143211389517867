/*
 * File : connectionStateEnum.ts
 * Created : March 2023
 * Authors :
 * Synopsis:
 *
 * Copyright 2023 Audinate Pty Ltd and/or its licensors
 *
 */
export enum ConnectionStateEnum {
  FAILED = 'failed',
  CONNECTED = 'connected',

  CLOSED = 'closed',

  DISCONNECTED = 'disconnected',
}
